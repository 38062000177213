import "./Cart.css";
import { MdClose } from "react-icons/md";
import { BsCartX } from "react-icons/bs";
import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactPixel from "react-facebook-pixel";

import {
  cartRemove,
  cartTotalpayableAmount,
  decreaseQuantity,
  postProductData,
} from "../../Redux/actions";
import { useNavigate } from "react-router-dom";

import setAuthorizationToken from "../../utils/commonFunction";
import { FaIndianRupeeSign } from "react-icons/fa6";
import Login from "../../Register/Register";
import { AuthContext } from "../../context/AuthContext";

const Cart = ({
  isCartOpen,
  setShowCart,
  setTest,
  test,
  cartTotal,
  setCartTotal,
}) => {
  const [isLogin, setIsLogin] = useState(false);
  const { setShowLoginModal } = useContext(AuthContext);

  const token = useSelector((store) => store.authReducer.userData);
  useEffect(() => {
    if (token) {
      setAuthorizationToken(token);
    }
  }, []);

  const guestUserCartDetails = useSelector((state) => state.addProduct.cart);
  
  const totalValue = guestUserCartDetails?.reduce((acc, cuu) => {
    acc = acc + cuu.price * cuu.quantity;
    return acc;
  }, 0);

  const tokenData = useSelector((store) => store.authReducer.userData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    document.addEventListener("click", () => {});
  }, []);

  const incrementQuantity = (item, quantity) => {
    dispatch(postProductData(item, quantity));
  };
  const decrementQuantity = (item, quantity) => {
    
    dispatch(decreaseQuantity(item, quantity));
  };

  const removeitem = (item) => {
    dispatch(cartRemove(item));
  };
  const returnShopPress = () => {
    navigate("/skinco");
    setShowCart(false);
  };

  const onClickPayment = () => {
    // setTest(true)
    // setAddressPress(true)

    ReactPixel.init("976335490593876");

    // Track page view event
    ReactPixel.pageView();
    if (!tokenData) {
      setShowCart(false);
      setShowLoginModal(true);
    } else {
      setShowCart(false);
      dispatch(cartTotalpayableAmount(totalValue));
      navigate(`/buy`, { state: isCartOpen });
    }
  };

  const closeclick = () => {
    dispatch(cartTotalpayableAmount(totalValue));
    setShowCart(false);
  };
  return (
    <div className="cart-container text-black">
      <div
        // className="cart-panel"
        className={test ? "cart-panelstyleadd" : "cart-panel"}
      >
        <div className="opac-layer" onClick={closeclick}></div>
        <div className="cart-content w-full md:w-auto h-full min-w-[300px] md:min-w-[400px] flex flex-col justify-between">
          <div className="">
            <div className="cart-header">
              <span className="heading">Shopping cart</span>
              <span className="close-btn" onClick={closeclick}>
                <MdClose />
                <span className="text">Close</span>
              </span>
            </div>
            <div className="max-h-[calc(100vh-192px)] overflow-y-auto overflow-x-hidden">
              {guestUserCartDetails?.length > 0 ? (
                guestUserCartDetails?.map((item, index) => {
                  {
                    
                  }
                  return (
                    <div className="my-4 mx-3 gap-3 flex items-center justify-between bg-[#edede9]">
                      <div>
                        <img
                          loading="lazy"
                          src={item.images[0]}
                          className="mx-2 h-[60px] w-[60px] sm:h-[80px] sm:w-[80px]"
                          alt="product"
                        />
                      </div>
                      <div className="cart-text-container mx-2">
                        <div className="flex gap-2">
                          <span className="line-clamp-2  text-xs mt-5  w-[170px] sm:w-[200px]">
                            {item?.name}
                          </span>
                          <MdClose
                            style={{ marginRight: "15px", marginTop: "10px" }}
                            onClick={() => removeitem(item)}
                          />
                        </div>

                        <div className="pricemaincontainer my-2">
                          <div className="quantityContainer">
                            {item.quantity === 1 ? (
                              <button
                                // onClick={() => decrementQuantity(item,-1)}
                                className="mr-[10px] border-r border-gray-400 px-2 cursor-not-allowed"
                                disabled
                              >
                                -
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  decrementQuantity(item, item.quantity)
                                }
                                className="mr-[10px] border-r border-gray-400 px-2 cursor-pointer"
                              >
                                -
                              </button>
                            )}

                            <div style={{ marginRight: "10px" }}>
                              {item?.quantity}
                            </div>

                            <button
                              onClick={() => incrementQuantity(item, 1)}
                              className="border-l border-gray-400 px-2 cursor-pointer"
                            >
                              +
                            </button>
                          </div>
                          <div
                          //   onClick={() => removeitem(item)}
                          >
                            <p>₹ {item.price * item.quantity}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="empty-cart">
                  <BsCartX />
                  <span>No products in the cart</span>
                  <button className="return-cta" onClick={returnShopPress}>
                    RETURN TO SHOP
                  </button>
                </div>
              )}
            </div>
          </div>

         {guestUserCartDetails?.length>0&& <React.Fragment>
            {/* <CartItem /> */}
            <div className="cart-footer">
              <div className="subtotal">
                <span className="text"> Subtotal:</span>
                <div className="flex items-center justify-center">
                  {/* <span> */}
                  <FaIndianRupeeSign />
                  <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                    {totalValue}
                  </p>
                  {/* </span> */}
                </div>

                {/* <p>Total Value: </p> */}
                {/* <p>{subamount}</p> */}

                {/* <span className="text total">&#8377;{"cartSubTotal"}</span> */}
              </div>
              <div className="button">
                <Login modalIsOpen={isLogin} setIsOpen={setIsLogin} />

                <button className="checkout-cta" onClick={onClickPayment}>
                  Checkout
                </button>
              </div>
            </div>
          </React.Fragment>}
        </div>
      </div>
      {/* {
        <AddressModal addressmodal={addressmodal}/>
      } */}
    </div>
  );
};

export default Cart;
