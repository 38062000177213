import React, { useState } from "react";
import "./Register.css";
import leftimage from "./login.png";
import Modal from "react-modal";
import login_icon from "./login_icon.png";
import { postApiData } from "../utils/services";
import setAuthorizationToken from "../utils/commonFunction";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userDetails } from "../Redux/actions";
import toast from "react-hot-toast";
const Login = ({ modalIsOpen, setIsOpen }) => {
  const [singuppress, setSignupPress] = useState(false);
  const customStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      border: "none", // Remove the border
      // Semi-transparent white background for the modal content
      boxShadow: "none", // Remove the shadow
      height: "400px",
      width: "700px",
      overflowX: "hidden",
      overflowY: "hidden",
      overflowX: "hidden",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      
    },
  };
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const loginPress = (e) => {
    e.preventDefault();
    const data = {
      phoneNumber: phoneNumber,
      otp: otp,
    };
    
    postApiData(
      "/user/login",
      data,
      (response) => {
        
        dispatch(userDetails(response));
        // localStorage.set("token",response)
        setAuthorizationToken(response);
        setIsOpen(false);
        // alert("logged In sucessfully")
        toast.success("logged in sucessfully!")
      },
      (error) => {
        
        // alert("details are incorrect")
        toast.error("Details are incorrect!")
      }
    );
  };
  const signupPressButton = (e) => {
    // 
    // setSignupPress(true)
    e.preventDefault();
  };
  const forgetPassword = () => {
    navigation("/resetPassword");
    setIsOpen(false);

    const data = {};
    postApiData("user/forgotPassword");
  };
  const sendotp = (e) => {
    e.preventDefault();
    let data = {
      phoneNumber: phoneNumber,
    };
    postApiData(
      "/user/sendOtp",
      data,
      (response) => {
        
        // alert("Otp send Sucessfully");
        toast.success("Otp send Sucessfully!")
      },
      (error) => {
        
      }
    );
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      //onAfterOpen={afterOpenModal}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      // style={{}}
      contentLabel="Example Modal"
      // style={{width:'300px',height:'290px'}}
      className="logindiv "
    >
      <div className="left">
        <img  loading="lazy" src={leftimage} alt="image" />
      </div>
      <div className="right">
        <img  loading="lazy" src={login_icon} alt="login icon" />
        <h2>Welcome Back</h2>
        <h2>Login to continue</h2>
        <form>
          <div className="tbox">
            <input
              type="text"
              name="phoneNumber"
              placeholder="Enter your Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <button onClick={sendotp} className="signup-button">
            Send Otp
          </button>
          <div className="tbox">
            <input
              
              name="otp"
              placeholder="Enter Otp"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          <div class="button-container">
            <button class="login-button" onClick={loginPress}>
              Login
            </button>

            {/* {
              singuppress && <SignUp/>
            } */}
            {/* {singuppress && (
            <SignUp modalIsOpen={singuppress} setIsOpen={true} />

          )} */}
          </div>

          <div class="link-container">
            {/* <a class="forgot-password-link" href="#"> */}
            {/* <p onClick={forgetPassword}>
            Forgot your password?

            </p> */}
            {/* </a> */}
          </div>
        </form>
      </div>
    </Modal>
  );

  /*return(
    <div className="overlay">
      <div className="modalContainer">
        <img  loading="lazy" className="img" src={leftimage} alt="image"/>
        <div className="modelRight">
          <p onClick={onclose} className="closeBtn">X</p>
          <div className="content">
            <img  loading="lazy" src={login_icon} alt="login icon" />
            <h2>Welcome Back</h2>
            <h2>Login to continue</h2>
            <form onSubmit={submit}>
              <div className="tbox">
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="tbox">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div class="button-container">
                <button class="login-button">Login</button>
              </div>

              <div class="link-container">
                <a class="forgot-password-link" href="#">
                  Forgot your password?
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )*/
};

export default Login;