import { FaPlay } from "react-icons/fa";
import { Link } from "react-router-dom";

const SideVideos = ({ video }) => {
  const { thumbnailUrl, title, description, duration ,_id} = video;
  function convertDuration(durationInSeconds) {
    const minutes = Math.floor(durationInSeconds / 60); // Get whole minutes
    const seconds = Math.round(durationInSeconds % 60); // Get remaining seconds

    // Format to ensure two decimal places for seconds
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  }

  return (
    <>
      <div className="w-full cursor-pointer">
        <div className="relative  w-full">
          {thumbnailUrl && (
            <img
              src={thumbnailUrl}
              className="h-full  rounded-xl w-full object-cover"
            />
          )}
          <Link
           to={`/training/${_id}`}
            className="rounded-xl absolute w-full h-full top-0 left-0 bg-black opacity-0 z-10 transition-opacity duration-300 hover:opacity-40 "
          />
       

          <div className="absolute flex items-center gap-1 text-xs md:text-sm bg-black text-white p-1 rounded top-2 right-2">
            <FaPlay />

            {convertDuration(duration)}
          </div>
        </div>
        <h2 className="text-xs md:text-sm max-h-[40px] overflow-hidden text-ellipsis line-clamp-2 font-bold my-3">
  {title}
</h2>
        </div>
    </>
  );
};

export default SideVideos;
