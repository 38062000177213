export const LOCAL_IMAGES = {
  BRAND_IMG: require('../../src/assets/images/Argatin.png'),
  SPRING_IMG: require('../../src/assets/images/springh2o.png'),
  SKINCO_IMG: require('../../src/assets/images/skinco.png'),
  ARGATIN_IMG: require('../../src/assets/images/Argatin.png'),
  NOT_FOUND: require('../../src/assets/images/no_item.webp'),
  APP_LOGO: require('../../src/assets/images/applogo.jpg'),
  email_icon: require('../../src/assets/images/email_icon.png'),
  call_icon: require('../../src/assets/images/call_icon.png'),
  facebook_icon: require('../../src/assets/images/facebook.png'),
  instagram_icon: require('../../src/assets/images/instagram.png'),
  pinterest_icon: require('../../src/assets/images/pinterest.png'),
  linkein_icon: require('../../src/assets/images/linkedine.png'),
  twitter_icon: require('../../src/assets/images/twitter.png'),
  youtube: require('../../src/assets/images/youtube.png'),
  line_icon: require('../../src/assets/images/line.png'),
  skinbanner_icon: require('../../src/assets/images/Skin-banner.png'),
  skinbanner_icon2: require('../../src/assets/images/Skin-banner2.png'),
  skinbanner_icon3: require('../../src/assets/images/Skin-banner3.png'),
  argatinbanner_icon: require('../../src/assets/images/argatin_banner.jpg'),
  banner_icon: require('../../src/assets/images/banner.jpg'),
  // home page banner
  banner1: require('../../src/assets/images/banner1.png'),
  banner2: require('../../src/assets/images/banner2.png'),
  banner3: require('../../src/assets/images/banner3.png'),
  banner4: require('../../src/assets/images/banner4.png'),
  newBanner1: require('../../src/assets/images/newBanner.png'),
  newBanner2: require('../../src/assets/images/newBanner2.png'),
  square: require('../../src/assets/images/square.avif'),
  skinco: require('../../src/assets/images/Skinbanner.jpg'),
  argatinbanner: require('../../src/assets/images/argatinbanner.jpg'),
  argatinbanner1: require("../../src/assets/images/argatinbanner1.png"),
  argatinbanner2: require("../../src/assets/images/argatinbanner2.png"),
  linechnage: require('../../src/assets/images/linechange.png'),
  medicare: require('../../src/assets/images/meni_&_pedi_kit.jpg'),
  facial: require('../../src/assets/images/facial.jpg'),
  appbanner: require('../../src/assets/images/app_banner.png'),
  offer: require('../../src/assets/images/OFFER.jpg'),
  spring: require('../../src/assets/images/SPRINGHBANNER.png'),
  spring1: require('../../src/assets/images/spring1.png'),
  spring2: require('../../src/assets/images/spring2.png'),
  spring3: require('../../src/assets/images/spring3.png'),
  signup: require('../../src/assets/images/signup.png'),



  //  argatinbrand 
  argatinbrand1: require('../../src/assets/images/argatinbrand1.png'),
  argatinbrand2: require('../../src/assets/images/argatinbrand2.png'),
  argatinbrand3: require('../../src/assets/images/argatinbrand3.png'),
  argatinbrand4: require('../../src/assets/images/argatinbrand4.png'),

  // selling brands
  brand_logo3: require('../../src/assets/images/brand_logo3.png'),
  brand_logo7: require('../../src/assets/images/brand_logo7.png'),
  brand_logo8: require('../../src/assets/images/brand_logo8.png'),
  brand_logo4: require('../../src/assets/images/brand_logo3.png'),
  brand_logo5: require('../../src/assets/images/brand_logo7.png'),
  brand_logo6: require('../../src/assets/images/brand_logo8.png'),

  //loveus
  loveusicon1: require('../../src/assets/images/icon1.png'),
  loveusicon2: require('../../src/assets/images/icon2.png'),
  loveusicon3: require('../../src/assets/images/icon3.png'),
  loveusicon4: require('../../src/assets/images/icon4.png'),
  loveusicon5: require('../../src/assets/images/icon5.png'),
  app_banner: require('../../src/assets/images/app_banner.png'),
  //signin
  signUpicon: require('../../src/assets/images/signIn.png'),
  menuIcon: require('../../src/assets/images/menu.png'),
  packageIcon: require('../../src/assets/images/package.png'),
  userIcon: require('../../src/assets/images/user.png'),
  orderProcess: require('../../src/assets/images/order-processed.png'),
  downArrow: require('../../src/assets/images/down-arrow.png'),
  leftArrow: require('../../src/assets/images/left-arrow.png'),
  aboutusImg: require('../../src/assets/images/about_banner.jpg'),
  ab1: require('../../src/assets/images/Ab1.jpg'),
  ab2: require('../../src/assets/images/ab2.jpg'),

  //gif
  popupgif: require('../assets/images/successfulpopup.gif'),
  //testimonials
  women1: require('../../src/assets/images/women1.jpg'),
  women2: require('../../src/assets/images/women2.jpg'),
  women3: require('../../src/assets/images/women3.jpg'),
  women4: require('../../src/assets/images/women4.jpg'),
  women5: require('../../src/assets/images/women5.jpg'),
  addbanner: require('../../src/assets/images/addbanner.png'),
  qualityimg: require('../../src/assets/images/quality.png'),
  packaging: require('../../src/assets/images/packaging.png'),
  productsImg: require('../../src/assets/images/products.png'),

  //brand image
  cer1: require('../../src/assets/images/cer1.png'),
  cer2: require('../../src/assets/images/cer2.png'),

  cer3: require('../../src/assets/images/cer3.png'),

  cer4: require('../../src/assets/images/cer4.png'),

  cer5: require('../../src/assets/images/cer5.png'),

  cer6: require('../../src/assets/images/cer6.png'),

  cer7: require('../../src/assets/images/cer7.png'),

  cer8: require('../../src/assets/images/cer8.png'),

  cer9: require('../../src/assets/images/cer9.png'),

  cer10: require('../../src/assets/images/cer10.png'),
  cer11: require('../../src/assets/images/cer11.png'),

  cer12: require('../../src/assets/images/cer12.png'),

  //distribution page images
  image1: require('../../src/assets/images/1.png'),
  image2: require('../../src/assets/images/2.png'),
  image3: require('../../src/assets/images/3.png'),
  image4: require('../../src/assets/images/4.png'),
  // nav bar images
  kartIcon: require('../../src/assets/images/kart.png'),
  argatin1: require('../../src/assets/images/argatin1.jpg'),
  // loreal
  loreal1: require('../../src/assets/images/loreal1.png'),
  loreal2: require('../../src/assets/images/loreal2.png'),
  loreal3: require('../../src/assets/images/loreal3.png'),
  loreal4: require('../../src/assets/images/loreal4.png'),
  loreal12: require('../../src/assets/images/loreal12.png'),


  //distributer banner 
  distributerImg: require('../../src/assets/images/distributionbanner.png'),
  packageImg: require('../../src/assets/images/packagingimg.png'),

  productdist: require('../../src/assets/images/productsdist.png'),



  //loreal brands category
  lorealconditioner: require('../../src/assets/images/lorealcond.png'),
  lorealmask: require('../../src/assets/images/lorealmask.png'),
  lorealoil: require('../../src/assets/images/lorealoil.png'),
  lorealshampoo: require('../../src/assets/images/lorealshampoo.png'),


  //skin brand
  facialkit: require('../../src/assets/images/facialkit.jpg'),
  menikit: require('../../src/assets/images/meni.jpg'),
  meesho: require("../../src/assets/images/8-300x150.png"),
  amazon: require("../../src/assets/images/3-300x150.png"),
  myntra: require("../../src/assets/images/9-300x150.png"),
  flipkart: require("../../src/assets/images/5-300x150.png"),
  nykaa: require("../../src/assets/images/10-300x150.png"),
  tata1mg: require("../../src/assets/images/14-300x150.png"),


}
