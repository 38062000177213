
const n = typeof document !== "undefined" ? document : {};

const useScrollBlock = () => {
  const { documentElement: html, body } = n;

  const blockScroll = () => {
    if (!body || !body.style ) return;

    const scrollbarWidth = window.innerWidth - html.clientWidth;
    const currentPaddingRight = parseInt(window.getComputedStyle(body).getPropertyValue("padding-right")) || 0;

    // Prevent layout shifts by adding padding equal to the scrollbar width
    html.style.position = 'relative';
    html.style.overflow = 'hidden';
    body.style.position = 'relative';
    body.style.overflow = 'hidden';
    body.style.paddingRight = `${currentPaddingRight + scrollbarWidth}px`;

  };

  const unblockScroll = () => {
    if (!body || !body.style ) return;
   
    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

  };

  // Toggle scroll blocking based on input flag
  return (shouldBlock) => {
    if (shouldBlock) {
      blockScroll();
    } else {
      unblockScroll();
    }
  };
};

export default useScrollBlock ;
