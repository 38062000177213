import { createContext, useState } from "react";

const AuthContext = createContext();
const AuthContextProvider = ({ children }) => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  return (
    <AuthContext.Provider value={{ showLoginModal, setShowLoginModal }}>
      {children}
    </AuthContext.Provider>
  );
};


export { AuthContextProvider,AuthContext };
