import { BsInstagram } from "react-icons/bs";
import { FaFacebook, FaYoutube, FaTwitter, FaPinterest } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoMdCall } from "react-icons/io";
import { IoMailSharp } from "react-icons/io5";

const NewFooter = () => {
  const sections = [
    {
      heading: "About",
      links: [
        { name: "About us", to: "/aboutus" },
        { name: "Faq", to: "/faq" },
      ]
    },
    {
      heading: "Our Brands",
      links: [
        { name: "Argatin", to: "/argatin" },
        { name: "Skin Co", to: "/skinco" },
        { name: "Spring H2O", to: "/spring" }
      ]
    },
    {
      heading: "Policies",
      links: [
        { name: "Privacy Policy", to: "/privacypolicy" },
        { name: "Terms & Conditions", to: "/termsandcondition" },
        { name: "Refund & Return Policy", to: "/returnrefund" }
      ]
    },
    {
      heading: "Get in touch",
      contacts: [
        { icon: <IoMdCall className="text-xl font-bold" />, info: "9315743367" },
        { icon: <IoMailSharp className="text-xl font-bold" />, info: "info@theprofessionalworld.com" }
      ]
    },
    {
      heading: "Our Social Media",
      socialLinks: [
        { icon: <BsInstagram />, href: "https://www.instagram.com/argatinkeratin/" },
        { icon: <FaFacebook />, href: "https://www.facebook.com/people/Skinco/100085397768322/" },
        { icon: <FaYoutube />, href: "https://www.youtube.com/channel/UCOoZ6zYRKHJ-8jFZjR-Ar1A" },
        { icon: <FaTwitter />, href: "https://twitter.com/argatinkeratin" },
        { icon: <FaPinterest />, href: "https://in.pinterest.com/skinconyc/" }
      ]
    }
  ];
  return (
    <div className="p-5 md:p-20 .font-grotesk flex flex-col bg-black text-white w-full font-Inter tracking-[0.05rem] ">
      <div className="flex justify-between gap-x-3 gap-y-6 md:justify-around items-start  text-lg  flex-wrap">
      {sections.map((section, index) => (
        <div key={index} className="flex flex-col justify-start items-start">
          {/* Render heading */}
          <h4 className="text-xs font-bold md:text-[0.8rem] mb-2 md:mb-5 text-white">{section.heading}</h4>

          {/* Render links */}
          {section.links &&
            section.links.map((link, idx) => (
              <Link key={idx} to={link.to} className="text-xs hover:underline hover:underline-offset-2 mt-2 text-white/80 cursor-pointer">
                {link.name}
              </Link>
            ))}

          {/* Render contacts */}
          {section.contacts &&
            section.contacts.map((contact, idx) => (
              <h2
                key={idx}
                className="text-sm text-white/80 cursor-pointer flex justify-center items-center  gap-x-3 mt-2"
              >
                {contact.icon} {contact.info}
              </h2>
            ))}

          {/* Render social media icons */}
          {section.socialLinks && (
            <div className="flex flex-row gap-x-3 md:gap-x-5">
              {section.socialLinks.map((social, idx) => (
                <a key={idx} href={social.href}>
                  <h2 className="text-md text-white/80 cursor-pointer hover:scale-110">{social.icon}</h2>
                </a>
              ))}
            </div>
          )}
        </div>
      ))}
      </div>
      <div className="border-t-2 border-white mt-4">
        <h2 className="text-xs md:text-md mb-2 md:mb-5  text-white text-center mt-3">
          The Professional Hair Salon & Spa (India) Pvt. Ltd.
        </h2>
        <h2 className="text-xs md:text-md mb-2 md:mb-5  text-white text-center mt-3">
          Address: Plot No-31 Okhla Industrial Estate, Phase – 3, South Delhi,
          New Delhi – 110020
        </h2>
      </div>
    </div>
  );
};

export default NewFooter;
