// import { instance } from "./services";
import { store } from "../Redux/store/store";
import { instance } from "./services";

export  function decodeHtml(html) {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}
const setAuthorizationToken = (token) =>{
//     const authToken = store.getState()
// const token=authToken.authReducer.userData
    if(token){
      //  instance.defaults.headers.Authorization = `Bearer ${token}`;
    }
  }
  export default setAuthorizationToken;