import { useState, useEffect } from "react";
import { IoSearchOutline } from "react-icons/io5";

const TypingSearchInput = ({searchTerm,handleSearch}) => {
  const placeholders = ["Shampoo", "Oil", "Serum", "Conditioner"];
  const [currentText, setCurrentText] = useState(""); // To display current text
  const [isDeleting, setIsDeleting] = useState(false); // Toggle between typing and deleting
  const [currentIndex, setCurrentIndex] = useState(0); // Track which placeholder to use
  const [charIndex, setCharIndex] = useState(0); // Track current character in the placeholder
  const typingSpeed = 150; // Speed of typing in ms
  const deletingSpeed = 100; // Speed of deleting in ms
  const pauseTime = 1000; // Time to pause after fully typing the word

  useEffect(() => {
    // Get the current placeholder text
    const currentPlaceholder = placeholders[currentIndex];

    // Logic to handle typing and deleting
    if (isDeleting) {
      // Deleting one character at a time
      if (charIndex > 0) {
        setTimeout(() => {
          setCurrentText(currentPlaceholder.substring(0, charIndex - 1));
          setCharIndex(charIndex - 1);
        }, deletingSpeed);
      } else {
        // When the word is fully deleted, switch to the next placeholder
        setIsDeleting(false);
        setCurrentIndex((prevIndex) => (prevIndex + 1) % placeholders.length); // Move to next placeholder
      }
    } else {
      // Typing one character at a time
      if (charIndex < currentPlaceholder.length) {
        setTimeout(() => {
          setCurrentText(currentPlaceholder.substring(0, charIndex + 1));
          setCharIndex(charIndex + 1);
        }, typingSpeed);
      } else {
        // Once the word is fully typed, pause for a bit before deleting
        setTimeout(() => {
          setIsDeleting(true);
        }, pauseTime);
      }
    }
  }, [charIndex, isDeleting, currentIndex]);

  return (
    <div className="relative w-[230px] xl:w-[300px] border text-xs flex items-center justify-center py-1 border-white h-[30px]">
    <input
      className="outline-none border-none   text-white   w-full bg-black  px-5 placeholder:text-xs placeholder:text-white"
      type="search"
      value={searchTerm}
      onChange={handleSearch}
      placeholder={`Search - ${currentText}`}
    />
    <div className="absolute top-[4px] brightness-150 -translate-x-[10px] right-0">
      <IoSearchOutline className="text-white " size={20} />
    </div>
  </div>
  );
};

export default TypingSearchInput;
