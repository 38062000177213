import { useContext, useEffect, useState } from "react";
import logo from "./img/logo.png";
import user from "./img/user.png";
import { PiCirclesThreePlusBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import Cart from "../commonComponent/cardDetails";
import { Link, useNavigate } from "react-router-dom";
import AddressModal from "../commonComponent/addressModal";
import "./Nav.css"; // Import your custom CSS file
import { IoCartOutline } from "react-icons/io5";

import { userLogOut } from "../Redux/actions";
import ReactGA from "react-ga";
import { CartContext } from "../context/CartContext";
import SmallNavbar from "./SmallNavbar";
import TypingSearchInput from "../components/customInputs/TypingInput";
import { LOCAL_IMAGES } from "../utils/localimages";
import LoginModal from "../components/modals/LoginModal";
import { AuthContext } from "../context/AuthContext";

const TRACKING_ID = "G-56Q0JTSZKG";
ReactGA.initialize(TRACKING_ID);
const Navbar = () => {
  const navigate = useNavigate();
  const { isCartOpen, setIsCartOpen, cartTotal } = useContext(CartContext);

  const { showLoginModal, setShowLoginModal } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [isbrand, setBrand] = useState(false);
  const [options, setOptions] = useState(false);
  const [hamburgermenu, sethamburgermenu] = useState(false);

  const cardData = useSelector((state) => state.addProduct);
  const authToken = useSelector((state) => state?.authReducer?.userData);
  const cartLengthData = useSelector((store) => store.addProduct.cart);

  const [showCart, setShowCart] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [test, setTest] = useState(isCartOpen);
  const [search, setSearch] = useState("");
  const [branddetails, setbrandDetails] = useState(false);
  const dispatch = useDispatch();
  //const [islogin, setLogin] = useState(true);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleOutsideClick = (event) => {
    if (!event.target.closest(".dropdown-menu")) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (modalIsOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [modalIsOpen]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    // Update isSmallScreen state on window resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1000);
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const apidata = {
    brand: "",
  };

  const onpress = () => {
    setIsOpen(false);
    setShowCart(true);
    setIsCartOpen(true);
  };

  const openUserModal = () => {
    if (!authToken) {
      setShowLoginModal(true);
    } else {
      setIsOpen(!modalIsOpen);
    }
  };

  function loginmodelpress() {
    setLoginModal(!loginModal);
    setIsOpen(!modalIsOpen);
  }

  function toggleOption() {
    setOptions(!options);
  }
  const logout = () => {
    dispatch(userLogOut());
  };

  function handlemouseEnter() {
    setBrand(true);
  }
  function handlemouseLeave() {
    setBrand(false);
  }

  const hanldeMyProfileButton = () => {
    setIsOpen(!modalIsOpen);
    navigate("/myprofile");
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsSticky(scrollTop > 200); // Set isScrolled based on scroll position
      if (scrollTop > 200) {
        setIsSticky(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSearch = (event) => {
    const value = event.target.value;
    if (value === "") {
      setSearchTerm("");
      navigate("/");
    } else {
      setSearchTerm(value);
      navigate(`/search${value ? `?text=${value}` : "text=''"}`);
    }
  };
  const linkData = [
    {
      pathname: "/best-sellers",
      text: "BestSellers",
    },
    {
      text: "Brands",
      onClick: handlemouseEnter, // Example onClick handler
      dropdown: true, // Indicating that this has a dropdown
    },
    {
      pathname: "/hair-care",
      text: "Haircare",
    },
    {
      pathname: "/skin-care",
      text: "Skincare",
    },
    {
      pathname: "/distributer",
      text: "Distribution",
    },
    {
      pathname: "/contactus",
      text: "Contact Us",
    },
    {
      pathname: "/product-combo",
      text: "Product combo",
    },
  ];

  const brandData = [
    {
      to: "/skinco",
      src: LOCAL_IMAGES.SKINCO_IMG,
      alt: "Skinco logo",
    },
    {
      to: "/spring",
      src: LOCAL_IMAGES.SPRING_IMG,
      alt: "Spring logo",
    },
    {
      to: "/argatin",
      src: LOCAL_IMAGES.ARGATIN_IMG,
      alt: "Argatin logo",
    },
  ];

  return (
    <>
      <div className="bg-black  relative">
        {isSmallScreen && <SmallNavbar setShowModal={setShowLoginModal} />}
        {!isSmallScreen && (
          <>
            <div className="flex w-full border border-b border-white/20 border-x-0 border-t-0 py-4 items-center justify-between px-[2%] xl:px-[5%]  xl:pr-9 ">
              <div className=" w-full xl:pl-[4%] flex gap-6 xl:gap-20 items-center">
                <div className=" ">
                  <Link
                    to={"/"}
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    className="h-full w-full"
                  >
                    <img
                      loading="lazy"
                      className="h-[35px] min-w-[140px]"
                      src={logo}
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="flex gap-6 xl:gap-9   items-center">
                  {brandData.map((brand, idx) => {
                    const { to, src, alt } = brand;
                    return (
                      <Link to={to} className="">
                        <img
                          loading="lazy"
                          className={` ${
                            idx > 0 ? "-mb-2" : "mb-0"
                          } h-[22px] xl:h-[25px] w-[90px] xl:w-[100px]`}
                          src={src}
                          alt={alt}
                        />
                      </Link>
                    );
                  })}
                  <TypingSearchInput
                    searchTerm={searchTerm}
                    handleSearch={handleSearch}
                  />
                </div>
              </div>
              <div className="flex items-center gap-6 cursor-pointer">
                <img
                  className=" hover:scale-125 h-7 w-7 hover:duration-100 hover:font-bold"
                  src={user}
                  alt="user icon"
                  onClick={openUserModal}
                />

                {modalIsOpen && authToken && (
                  <div
                    className={`py-6
                      ${authToken ? "dropdown-menu" : "dropdown-menulogout"}
                    `}
                  >
                    <div
                      onClick={hanldeMyProfileButton}
                      className="dropdown-submenu hover:bg-gray-100  py-2 px-6"
                    >
                      <div className="submenu-content">
                        <h2 className="font-medium">My Profile</h2>
                      </div>
                    </div>

                    <div className="dropdown-submenu hover:bg-gray-100  py-2 px-6">
                      <div className="submenu-content">
                        <h2 className="font-medium" onClick={logout}>
                          Logout
                        </h2>
                      </div>
                    </div>
                  </div>
                )}

                {/* <div className="cart-container"> */}
                <div onClick={onpress} className="relative ">
                  <p className="text-xs text-white flex items-center justify-center bg-green-500 rounded-[100%] h-5 w-5 absolute -right-1 -top-1  ">
                    {cartLengthData.length}
                  </p>
                  <IoCartOutline
                    size={30}
                    className=" font-bold text-gray-300"
                  />
                </div>
                {/* {showCart && (
                <Cart setShowCart={setShowCart} setTest={setTest} test={test} />
              )} */}
                {isCartOpen && (
                  <Cart
                    setShowCart={setIsCartOpen}
                    isCartOpen={isCartOpen}
                    setTest={setTest}
                    test={test}
                  />
                )}
                {/* </div> */}
                <PiCirclesThreePlusBold
                  className="menu-icon"
                  onClick={toggleOption}
                />
              </div>
            </div>

            <div
              className={`absolute flex -bottom-[44px]   w-full px-[5%] xl:px-[10%] bg-black font-Inter mx-auto items-center  justify-around  ${
                isSticky ? "fade-out-top" : ""
              }`}
            >
              {" "}
              {linkData.map((link, index) => {
                if (link.dropdown) {
                  return (
                    <button
                      key={index}
                      className=" relative border-b-2 py-3 border-transparent hover:border-white  hover:border-b-2  text-white tracking-[0.2em]  whitespace-nowrap text-[12px] uppercase  transition duration-150 ease-in-out  w-fit    "
                      onClick={()=>setBrand(!isbrand)}
                      onMouseEnter={link.onClick}
                    >
                      {link.text}
                      {isbrand && (
                        <div
                          onMouseLeave={handlemouseLeave}
                          className="absolute min-w-[150px]  flex flex-col text-black shadow-md border -left-[20px] -bottom-[131px] z-10 bg-white p-3"
                        >
                            <Link
                            to="/spring"
                            className="text-sm text-gray-600 hover:underline hover:underline-black text-start pb-2 last:pb-0"
                            onClick={() => setBrand(false)}
                          >
                            Spring H2O
                          </Link>
                          <Link
                            to="/argatin"
                            className="text-sm text-gray-600 hover:underline hover:underline-black text-start pb-2 last:pb-0"
                            onClick={() => setBrand(false)}
                          >
                            Argatin
                          </Link>
                          <Link
                            to="/skinco"
                            className="text-sm text-gray-600 hover:underline hover:underline-black text-start pb-2 last:pb-0"
                            onClick={() => setBrand(false)}
                          >
                            Skinco
                          </Link>
                          <Link
                            to="/loreal"
                            className="text-sm text-gray-600 hover:underline hover:underline-black text-start pb-2 last:pb-0"
                            onClick={() => setBrand(false)}
                          >
                            Loreal
                          </Link>
                        
                       
                        
                        </div>
                      )}
                    </button>
                  );
                }
                return (
                  <Link
                    key={index}
                    to={link.pathname}
                    className="  text-white border-b-2 py-3 border-transparent hover:border-white  hover:border-b-2 tracking-[0.2em]  whitespace-nowrap text-[12px] uppercase  transition duration-150 ease-in-out  w-fit    "
                    style={{ textDecoration: "none" }}
                  >
                    {link.text}
                  </Link>
                );
              })}
            </div>
          </>
        )}

        {test && <AddressModal addressmodal={test} setAddressModal={setTest} />}
      </div>
      <LoginModal show={showLoginModal} setShow={setShowLoginModal} />
    </>
  );
};

export default Navbar;
