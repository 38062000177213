
import { combineReducers } from 'redux';
import { addProduct, filterReducer } from '../reducers';
import { authReducer,cartDetailsReducer } from '../reducers';
import { paymentTotalAmount } from '../reducers';

const rootReducer=combineReducers({
   addProduct,
   authReducer,
   cartDetailsReducer,
   filterReducer

   // paymentTotalAmount   
})
export default rootReducer;